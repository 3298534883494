@import 'tailwindcss/components';

.subjectwell {
  --color-primary: #1f4357;
  --color-secondary: #3e9bd5;
  --color-error: #dc5b57;
}

.subjectwell.green-version {
  --color-primary: #034732;
  --color-secondary: #193300;
  --color-error: #F57A00;
}

.subjectwell.green-version section.blue-section {
  background: linear-gradient(155deg,#F5FFF0 0%,#D8FDF1 100%);
}

section.blue-section {
  background: linear-gradient(155deg,#f3faff 0%,#e4f4ff 100%);
}

.subjectwell .subscribe {
  top: -1.15rem;
}

.subjectwell-cta  {
  transition: all 0.35s ease;
  background: linear-gradient(var(--color-primary), var(--color-primary) 50%, var(--color-secondary) 50%, var(--color-secondary));
  background-size: 100% 205% !important;
}

.subjectwell-cta.coral {
  background: linear-gradient(var(--color-secondary), var(--color-secondary) 50%, var(--color-error) 50%, var(--color-error));
}

.lightbox {
  transition: all 0.35s ease;
  @apply opacity-0 invisible z-40;
  transform: translate(0, -100vh);
  color: #333;
}
.lightbox.active {
  @apply opacity-100 visible;
  transform: translate(0);
}

.mobile-menu-btn {
  @apply z-50;
  top: 20px;
  right: 20px;
  transition: all 0.35s ease;
}
.menu-btn-contain {
  border-radius: 50%;
  transition: all 0.35s ease;
}
.menu-bar {
  background-color: #333;
  transition: all 0.35s ease;
}
.menu-btn:before, .menu-btn:after {
  content: "";
  @apply h-1 absolute w-full;
  background-color: #333;
  transition: all 0.35s ease;
}
.menu-btn:before {
  top: 0;
}
.menu-btn:after {
  bottom: 0;
}

.mobile-menu-btn.active .menu-btn .menu-bar {
  display: none;
}
.mobile-menu-btn.active .menu-btn:after {
  bottom: auto;
  top: 50%;
  transform: rotate( -45deg);
}
.mobile-menu-btn.active .menu-btn:before {
  top: 50%;
  transform: rotate(45deg) !important;
}

.image-box {
  border-top-width: 11px;
  border-right-width: 11px;
  border-bottom-width: 11px;
  border-left-width: 11px;
  border-width: 11px;
  border-radius: 10px;
  box-shadow: 0 4px 10px 3px rgb(0 0 0 / 20%);
  transition: all 0.35s ease;
  z-index: 1;
}
.image-box .thumb {
  bottom: 14px;
  z-index: 5;
}
.image-box .thumb:before {
  @apply h-16 mx-0 my-auto absolute inset-y-0 left-0 w-full;
  content: "";
  background-color: var(--color-secondary);
  z-index: -1;
}
.play-btn {
  line-height: 75px;
  transition: all 0.35s ease;
  border-radius: 50%;
}

.sw-hero  {
  @apply bg-cover relative;
  background-image: url(https://www.subjectwell.com/wp-content/uploads/hero-lg-home.jpg);
  background-position: right center;
  min-height: 300px;
}

.sw-hero:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(24,53,68,.9);
}

.sw-hero.secondary {
  background-image: url('https://www.subjectwell.com/wp-content/themes/subjectwell-2020/assets/img/appearance/bg-prefooter.jpg');
}

.underlined {
  position: relative;
}
.underlined:after {
  @apply border-solid border-t-2 block h-3 mt-3 absolute;
  content: "";
  width: 45%;
  max-width: 190px;
  color: var(--color-error);
  transition: all 1s ease;
  left: 50%;
  transform: translateX(-50%);
}
.text-left.underlined:after {
  left: 0;
  transform: none;
}

li {
  transition: all 0.35s ease;
}

@media only screen and (min-width: 768px) {
  .underlined:after {
    border-top: 5px solid;
    margin-top: 16px;
  }
  
}

@media only screen and (min-width: 1280px) {
  .sw-hero {
    min-height: 400px;
  }
  .sw-hero:before {
    background: linear-gradient(90deg,var(--color-primary) 0%,transparent 100%);
  }
  .sw-hero.secondary {
    min-height: 200px;
  }
}
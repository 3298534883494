.pagy-nav {
  @apply flex space-x-2;
}
.pagy-nav .page a, .pagy-nav .page.active, .pagy-nav .page.prev.disabled, .pagy-nav .page.next.disabled {
  @apply transition block rounded-full px-3 py-1 text-sm text-black-base font-semibold bg-gray-light shadow-md;
}
.pagy-nav .page a:hover, .pagy-nav .page.active:hover, .pagy-nav .page.prev.disabled:hover, .pagy-nav .page.next.disabled:hover {
  @apply text-white bg-secondary;
}
.pagy-nav .page a:active, .pagy-nav .page.active:active, .pagy-nav .page.prev.disabled:active, .pagy-nav .page.next.disabled:active {
  @apply text-white bg-secondary;
}
.pagy-nav .page.prev.disabled, .pagy-nav .page.next.disabled {
  @apply text-gray-dark cursor-default;
}
.pagy-nav .page.prev.disabled:hover, .pagy-nav .page.next.disabled:hover {
  @apply cursor-not-allowed bg-gray-light text-gray-dark;
}
.pagy-nav .page.prev.disabled:active, .pagy-nav .page.next.disabled:active {
  @apply cursor-not-allowed bg-gray-light text-gray-dark;
}
.pagy-nav .page.active {
  @apply text-white cursor-default bg-primary;
}
.pagy-nav .page.active:hover {
  @apply text-white bg-secondary;
}
.pagy-nav .page.active:active {
  @apply text-white bg-secondary;
}

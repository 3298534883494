@import 'tailwindcss/components';
@import './lander_templates/subjectwell.css';

.heading-gradient {
  @apply text-7xl my-8 lg:my-12 font-bold text-transparent
  bg-clip-text bg-gradient-to-br text-transparent
  bg-clip-text bg-gradient-to-br from-green-500
  to-blue-700 filter drop-shadow;
}

.tippy-box[data-theme~='light'] {
  background: transparent;
}
.progress-bar {
  position: relative;
  z-index: 1;
}